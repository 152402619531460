import { useState } from 'react';
import { Skeleton } from '@mui/material';

// eslint-disable-next-line react/prop-types
const LoadingImage = ({src, alt, maxHeight = 118, maxWidth = 210, ...props}) => {
    const [imgLoaded, setImgLoaded] = useState(false);

    return (
        <div>
            <Skeleton variant="rectangular" width={maxWidth} height={maxHeight} style={{display: imgLoaded ? 'none' : 'block'}}/>
            <img
                src={src}
                alt={alt}
                onLoad={() => setImgLoaded(true)}
                style={{display: imgLoaded ? 'block' : 'none'}}
                {...props}
            />
        </div>
    );
};

export default LoadingImage;