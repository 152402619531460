import uploadPage from './upload-page';
import profilePage from './profile-page';
import myContributionsPage from './my-contributions-page';
import dashboardPage from './dashboard-page'
import ExplorePage from './explore-page';
import siteDetails from './site-details';
import AdminPage from './admin-page';
import ToolsPage from "menu-items/tools-page";

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [siteDetails, ExplorePage, uploadPage, profilePage, dashboardPage, ToolsPage, AdminPage]
};

export default menuItems;