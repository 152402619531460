import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import IconComponent from './IconComponent';
import ImageWithLoading from 'ui-component/image/ImageWithLoading';
import { environment } from 'config';
import Avatar from 'ui-component/extended/Avatar';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import HiddenImage from 'ui-component/image/HiddenImage';

const DropdownItem = ({ option }) => {
    const theme = useTheme();
    const navigate = useNavigate();

    const getNavigateTo = (type) => {
        switch (type) {
            case 'ObservationSearchDocument':
                return `/browse/observations/${option._id}`;
            case 'BookSearchDocument':
                return `/browse/books/${option._id}`;
            case 'UserSearchDocument':
                return `/profile/observations/${option._id}`;
            case 'BookPageSearchDocument':
                return `/browse/books/${option.containerID}`;
            case 'CollectionSearchDocument':
                return `/browse/collections/${option._id}`;
            case 'TaxonomySearchDocument':
                return `/browse/taxonomies`;
            default:
                return '/';
        }
    };

    const hoverEffect = {
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
            boxShadow: theme.shadows[3]
        }
    };

    let content = null;

    switch (option.type) {
        case 'ObservationSearchDocument':
            content = (
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <IconComponent text="ObservationSearchDocument" size={50} />
                    <Box sx={{ width: '75px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0 10px' }}>
                    {option.blobImage ? (
                        <ImageWithLoading
                            src={option.blobImage}
                            alt={option.title || option.caption || option.fileName || `file: ${option._id}`}
                            loading="lazy"
                            maxHeight={75}
                            maxWidth={75}
                            style={{
                                minHeight: '75px',
                                maxHeight: '75px',
                                maxWidth: '75px',
                                width: 'auto',
                                margin: '0 10px'
                            }}
                        />
                    ) : (
                        <HiddenImage
                            width={'75px'}
                            height={'75px'}
                            style={{
                                borderRadius: '5px',
                                boxShadow: '1px 1px 10px 1px grey',
                                margin: '0 10px'
                            }}
                        />
                    )}
                    </Box>
                    <Typography variant="body1" fontWeight="bold" color="error" sx={{ whiteSpace: 'normal', overflowWrap: 'break-word', flex: 1 }}>
                        Observation Title: {option.title}
                    </Typography>
                </Box>
            );
            break;
        case 'BookSearchDocument':
            content = (
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <IconComponent text="BookSearchDocument" size={50} />
                    <Box sx={{ width: '75px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0 10px' }}>
                    {option.representativePhotoID ? (
                        <ImageWithLoading
                            src={`${environment.API_URL}/api/fileBlob/image/${option.representativePhotoID}/ThumbnailSmall`}
                            alt={option.title || option.caption || option.fileName || `file: ${option._id}`}
                            loading="lazy"
                            maxHeight={75}
                            maxWidth={75}
                            style={{
                                minHeight: '75px',
                                maxHeight: '75px',
                                maxWidth: '75px',
                                width: 'auto',
                                margin: '0 10px'
                            }}
                        />
                    ) : (
                        <HiddenImage
                            width={'75px'}
                            height={'75px'}
                            style={{
                                borderRadius: '5px',
                                boxShadow: '1px 1px 10px 1px grey',
                                margin: '0 10px'
                            }}
                        />
                    )}
                    </Box>
                    <Typography variant="body1" fontWeight="bold" color="error" sx={{ whiteSpace: 'normal', overflowWrap: 'break-word', flex: 1 }}>
                        Book Title: {option.title}
                    </Typography>
                </Box>
            );
            break;
        case 'UserSearchDocument':
            content = (
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <IconComponent text="UserSearchDocument" size={50} />
                    <Box sx={{ width: '75px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0 10px' }}>
                    <Avatar
                        alt="User 1"
                        src={`${environment.API_URL}/api/fileBlob/user/${option._id}/avatar`}
                        sx={{
                            borderRadius: '16px',
                            width: 75,
                            height: 75,
                            margin: '0 10px'
                        }}
                    />
                    </Box>
                    <Typography variant="body1" fontWeight="bold" color="error" sx={{ whiteSpace: 'normal', overflowWrap: 'break-word', flex: 1 }}>
                        User: {option.username}
                    </Typography>
                </Box>
            );
            break;
        case 'BookPageSearchDocument':
            content = (
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <IconComponent text="BookPageSearchDocument" size={50} />
                    <Box sx={{ width: '75px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0 10px' }}>
                    <ImageWithLoading
                        src={`${environment.API_URL}/api/fileBlob/image/${option._id}/ThumbnailSmall`}
                        alt={option.title || option.caption || option.fileName || `file: ${option._id}`}
                        loading="lazy"
                        maxHeight={75}
                        maxWidth={75}
                        style={{
                            minHeight: '75px',
                            maxHeight: '75px',
                            maxWidth: '75px',
                            width: 'auto',
                            margin: '0 10px'
                        }}
                    />
                    </Box>
                    <Typography variant="body1" fontWeight="bold" color="error" sx={{ whiteSpace: 'normal', overflowWrap: 'break-word', flex: 1 }}>
                        Book Page: {option?.taxonRef?.canonicalName || option.title || 'Unidentified'}
                    </Typography>
                </Box>
            );
            break;
        case 'CollectionSearchDocument':
            content = (
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <IconComponent text="CollectionSearchDocument" size={50} />
                    <Box sx={{ width: '75px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0 10px' }}>
                    {option.representativePhotoId ? (
                        <ImageWithLoading
                            src={`${environment.API_URL}/api/fileBlob/image/${option.representativePhotoId}/ThumbnailSmall`}
                            alt={option.title || option.caption || option.fileName || `file: ${option._id}`}
                            loading="lazy"
                            maxHeight={75}
                            maxWidth={75}
                            style={{
                                minHeight: '75px',
                                maxHeight: '75px',
                                maxWidth: '75px',
                                width: 'auto',
                                margin: '0 10px'
                            }}
                        />
                    ) : (
                        <HiddenImage
                            width={'75px'}
                            height={'75px'}
                            style={{
                                borderRadius: '5px',
                                boxShadow: '1px 1px 10px 1px grey',
                                margin: '0 10px'
                            }}
                        />
                    )}
                    </Box>
                    <Typography variant="body1" fontWeight="bold" color="error" sx={{ whiteSpace: 'normal', overflowWrap: 'break-word', flex: 1 }}>
                        Collection Name: {option.collectionName}
                    </Typography>
                </Box>
            );
            break;
        case 'TaxonomySearchDocument':
            content = (
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <IconComponent text="TaxonomySearchDocument" size={50} />
                    <Box sx={{ width: '75px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0 10px' }}>
                        {option.representativePhotoID ? (
                            <ImageWithLoading
                                src={`${environment.API_URL}/api/fileBlob/image/${option.representativePhotoID}/ThumbnailSmall`}
                                alt={option.title || option.caption || option.fileName || `file: ${option._id}`}
                                loading="lazy"
                                maxHeight={75}
                                maxWidth={75}
                                style={{
                                    minHeight: '75px',
                                    maxHeight: '75px',
                                    maxWidth: '75px',
                                    width: 'auto',
                                    margin: '0 10px'
                                }}
                            />
                        ) : (
                            <HiddenImage
                                width={'75px'}
                                height={'75px'}
                                style={{
                                    borderRadius: '5px',
                                    boxShadow: '1px 1px 10px 1px grey',
                                    margin: '0 10px'
                                }}
                            />
                        )}
                    </Box>
                    <Typography variant="body1" fontWeight="bold" color="error" sx={{ whiteSpace: 'normal', overflowWrap: 'break-word', flex: 1 }}>
                        Taxon Name: {option.canonicalName || option.genus || option.family || option.class || option.phylum || option.kingdom}
                    </Typography>
                </Box>
            );
            break;
        default:
            content = null;
    }

    if (!content) return null;

    const handleClick = () => {
        const navigateToPath = getNavigateTo(option.type);

        switch (option.type) {
            case 'TaxonomySearchDocument':
                navigate(navigateToPath, {
                    state: {
                        taxonDetails: option
                    }
                });
                break;

            case 'BookPageSearchDocument':
                navigate(navigateToPath, {
                    state: {
                        pageData: option
                    }
                })
                break;
            default:
                navigate(navigateToPath);
                break;
        }
    };

    return (
        <Box
            component="li"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 1,
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                ...hoverEffect
            }}
            onClick={handleClick}
        >
            {content}
        </Box>
    );
};

DropdownItem.propTypes = {
    option: PropTypes.object
};

export default DropdownItem;
