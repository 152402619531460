import React from 'react';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import HubOutlinedIcon from '@mui/icons-material/HubOutlined';

const IconComponent = ({ text, size = 75 }) => {
    // Mapping text values to icons
    const iconMap = {
        BookSearchDocument: MenuBookOutlinedIcon,
        BookPageSearchDocument: DescriptionOutlinedIcon,
        UserSearchDocument: PersonOutlineOutlinedIcon,
        CollectionSearchDocument: CollectionsOutlinedIcon,
        ObservationSearchDocument: CameraAltOutlinedIcon,
        TaxonomySearchDocument: HubOutlinedIcon
    };

    // Function to get the icon based on text
    const getIconByText = (text) => {
        return iconMap[text] || null; // Returns the icon component or null if not found
    };

    const Icon = getIconByText(text);

    return (
        <div>
            {Icon ?
                <Icon
                sx={{
                    width: size, // Set width and height to the size prop
                    height: size,
                }}
            />
                : <span>No icon found</span>}
        </div>
    );
};

export default IconComponent;
