import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { getUniversalAutocomplete } from 'api/autocomplete';
import DropdownItem from 'ui-component/autocomplete/UniversalSearch/DropdownItem';
import { Popper } from '@mui/material';

const Index = ({ searchResults, setSearchResults, page = 1, setPage = () => 1, mapType = 'universal', viewDropDown = true, setSearchTerm }) => {
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);
    const abortControllerRef = useRef(null);

    const fetchSearchResults = async (query, append = false) => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort(); // Cancel previous request
        }

        const controller = new AbortController();
        abortControllerRef.current = controller;

        try {
            setLoading(true);
            const response = await getUniversalAutocomplete(query, 20, page, mapType, {
                signal: controller.signal // Attach the abort signal
            });

            const results = response || [];
            setSearchResults((prevResults) => (append ? [...prevResults, ...results] : results));
        } catch (error) {
            if (error.name !== 'AbortError') {
                console.error('Error fetching search results:', error);
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (inputValue || !viewDropDown) {
            setPage(1);
            fetchSearchResults(inputValue ? inputValue : ' ');
        } else {
            setSearchResults([]);
        }

        return () => {
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
        };
    }, [inputValue, mapType]);

    useEffect(() => {
        if (page !== 1) {
            fetchSearchResults(inputValue ? inputValue : ' ', true);
        }
    }, [page]);

    useEffect(() => {
        if (setSearchTerm) {
            setSearchTerm(inputValue);
        }
    }, [inputValue]);

    return (
        <Autocomplete
            fullWidth
            options={searchResults || []}
            value={inputValue}
            getOptionLabel={() => inputValue}
            onInputChange={(event, newValue) => setInputValue(newValue)}
            renderOption={(props, option) => (
                <div key={option._id}>
                    <DropdownItem option={option} />
                </div>
            )}
            renderInput={(params) => <TextField {...params} placeholder={mapType === 'books' ? 'Search Book Titles' : 'Search'} />}
            freeSolo
            filterOptions={(options) => options}
            PopperComponent={({ style, ...props }) => <Popper style={{ ...style, width: 'fit-content', maxWidth: '400px' }} {...props} />}
            open={viewDropDown && searchResults.length > 0} // Control dropdown visibility
        />
    );
};

Index.propTypes = {
    searchResults: PropTypes.array.isRequired,
    setSearchResults: PropTypes.func.isRequired,
    page: PropTypes.number,
    setPage: PropTypes.func,
    mapType: PropTypes.string,
    viewDropDown: PropTypes.bool,
    setSearchTerm: PropTypes.func
};

export default Index;
