import axios from "../utils/axios";

export const getSubjectAutocomplete: (
    userID: string,
    prefix: string
) => Promise<any> = async (
    userID: string,
    prefix: string
): Promise<any> => {
    const response = await axios.get(`api/autocomplete/subjects` , {
        params: {
            userID: userID,
            prefix: prefix
        }
    });
    return response?.data || null;
}


export const getTaxonomyAutocomplete: (
    userInput: string,
    kingdom: string
) => Promise<any> = async (
    userInput: string,
    kingdom: string
): Promise<any> => {
    const response = await axios.get(`api/autocomplete/taxonomy` , {
        params: {
            userInput: userInput,
            kingdom: kingdom
        }
    });
    return response?.data || null;
}

export const getElasticTaxonomyAutocomplete: (
    userInput: string,
    kingdom: string
) => Promise<any> = async (
    userInput: string,
    kingdom: string
): Promise<any> => {
    const response = await axios.get(`api/autocomplete/elastic/taxonomy` , {
        params: {
            userInput: userInput,
            kingdom: kingdom
        }
    });
    return response?.data || null;
}

export const getCollectionNameAutocomplete: (
    userID: string,
    prefix: string
) => Promise<any> = async (
    userID: string,
    prefix: string
): Promise<any> => {
    const response = await axios.get(`api/autocomplete/collectionName` , {
        params: {
            userID: userID,
            prefix: prefix
        }
    });
    return response?.data || null;
}

export const getAuthorsNameAutocomplete: (
    prefix: string
) => Promise<any> = async (
    prefix: string
): Promise<any> => {
    const response = await axios.get(`api/autocomplete/authors` , {
        params: {
            prefix: prefix
        }
    });
    return response?.data || null;
}

export const getUniversalAutocomplete: (
    searchTerm: string,
    limit: number,
    page: number,
    mapType: string
) => Promise<any> = async (
    searchTerm: string,
    limit: number,
    page: number,
    mapType: string
): Promise<any> => {
    const response = await axios.get(`api/autocomplete/universal` , {
        params: {
            searchTerm: searchTerm,
            limit: limit,
            page: page,
            mapType: mapType
        }
    });
    return response?.data || null;
}