import { lazy } from 'react';

// project imports
import GuestGuard from 'utils/route-guard/GuestGuard';
// import MinimalLayout from 'layout/MinimalLayout';
// import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';
import MainLayout from '../layout/MainLayout';
import UpdatePage from "views/casabio-info/update-page";
import HelmetWrapper from "routes/HelmetWrapper";
import {Navigate} from "react-router-dom";
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// login routing
const AuthLogin = Loadable(lazy(() => import('../views/system-utils/authentication/Login')));
const ForgotPassword = Loadable(lazy(() => import('../views/system-utils/authentication/ForgotPassword')));
const AuthRegister = Loadable(lazy(() => import('../views/system-utils/authentication/Register')));
const AuthCodeVerification = Loadable(lazy(() => import('../views/system-utils/authentication/CodeVerification')));
const HomePage = Loadable(lazy(() => import('views/home/Home')));
const ExplorePage = Loadable(lazy(() => import('views/explorer/explore-observations/explore-page')));
const ExploreCollectionsPage = Loadable(lazy(() => import('views/explorer/explore-collections/explore-page')));
const VersionPage = Loadable(lazy(()=> import('views/casabio-info/version-page')));
const AboutPage = Loadable(lazy(()=> import('views/casabio-info/about-page')));
const ContactUsPage = Loadable(lazy(()=> import('views/casabio-info/contact-page')));
const NewsPage = Loadable(lazy(()=> import('views/other/news-page')));
const DonateNowPage = Loadable(lazy(()=> import('views/donation/specify-donation-page')));
const DonationPage = Loadable(lazy(()=> import('views/donation/donation-page')));
const CalendarPage = Loadable(lazy(()=> import('views/other/calendar-page')));
const BookPage = Loadable(lazy(()=> import('views/explorer/explore-books/explore-page')));
const IndividualBookPage = Loadable(lazy(()=> import('views/explorer/explore-books/book-view')));
const ExploreTaxon = Loadable(lazy(()=> import('views/explorer/explore-taxon')));
const ExploreEtymology = Loadable(lazy(()=> import('views/explorer/explore-etymologies')));
const IndividualObservationPage = Loadable(lazy(()=> import('views/explorer/explore-observations/observation-view')));
//const HackathonPage = Loadable(lazy(()=> import('views/events/hackathon')));
const POPIPolicyPage = Loadable(lazy(()=> import('views/casabio-info/POPI-policy-page')));
const IndividualCollectionPage = Loadable(lazy(()=> import('views/explorer/explore-collections/collection-view')));
const Profile = Loadable(lazy(() => import('views/user/profile/social-profile')));
const ExploreUserPage = Loadable(lazy(()=> import('views/explorer/explore-users/explore-page')));
const CasabioChronicles = Loadable(lazy(()=> import('views/events/casabio-chronicles')));
const SkyIsland = Loadable(lazy(()=> import('views/events/sky-island')));
const Hackathon = Loadable(lazy(()=> import('views/events/hackathon')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: '/',
    element: (

            <GuestGuard>
                <MainLayout />
            </GuestGuard>

    ),
    children: [
        {
            path: '/',
            element: <HelmetWrapper canonicalUrl='/'><HomePage /></HelmetWrapper>
        },
        {
            path: '/login',
            element: <HelmetWrapper canonicalUrl='/login'><AuthLogin /></HelmetWrapper>
        },
        {
            path: '/register',
            element: <HelmetWrapper canonicalUrl='/register'><AuthRegister /></HelmetWrapper>
        },
        {
            path: '/code-verification/:token',
            element: <HelmetWrapper canonicalUrl='/code-verification'><AuthCodeVerification /></HelmetWrapper>
        },
        {
            path: '/home',
            element: <HelmetWrapper canonicalUrl='/home'><HomePage /></HelmetWrapper>
        },
        {
            path: '/browse/observations',
            element: <HelmetWrapper canonicalUrl='/browse/observations'><ExplorePage /></HelmetWrapper>
        },
        {
            path: '/forgot-password',
            element: <HelmetWrapper canonicalUrl='/forgot-password'><ForgotPassword /></HelmetWrapper>
        },
        {
            path: '/version',
            element: <HelmetWrapper canonicalUrl='/version'><VersionPage /></HelmetWrapper>
        },
        {
            path: '/about',
            element: <HelmetWrapper canonicalUrl='/about'><AboutPage /></HelmetWrapper>
        },
        {
            path: '/contact-us',
            element: <HelmetWrapper canonicalUrl='/contact-us'><ContactUsPage /></HelmetWrapper>
        },
        {
            path: '/news',
            element: <HelmetWrapper canonicalUrl='/news'><NewsPage /></HelmetWrapper>
        },
        {
            path: '/events/casabio-chronicles',
            element: <HelmetWrapper canonicalUrl='/events/casabio-chronicles'><SkyIsland /></HelmetWrapper>
        },
        {
            path: '/events/hackathon',
            element: <HelmetWrapper canonicalUrl='/events/hackathon'><Hackathon /></HelmetWrapper>
        },
        {
            path: '/casabio-chronicles',
            element: <HelmetWrapper canonicalUrl='/casabio-chronicles'><CasabioChronicles /></HelmetWrapper>
        },
        {
            path: '/componentTesting',
            element: <HelmetWrapper canonicalUrl='/componentTesting'><SamplePage /></HelmetWrapper>
        },
        {
            path: '/donations/donate',
            element: <HelmetWrapper canonicalUrl='/donations/donate'><DonateNowPage /></HelmetWrapper>
        },
        {
            path: '/donations',
            element: <HelmetWrapper canonicalUrl='/donations'><DonationPage /></HelmetWrapper>
        },
        {
            path: '/calendar',
            element: <HelmetWrapper canonicalUrl='/calendar'><CalendarPage /></HelmetWrapper>
        },
        {
            path: '/browse/books',
            element: <HelmetWrapper canonicalUrl='/browse/books'><BookPage /></HelmetWrapper>
        },
        {
            path: '/browse/books/:id',
            element: <HelmetWrapper canonicalUrl='/browse/books'><IndividualBookPage /></HelmetWrapper>
        },
        {
            path: '/browse/taxonomies',
            element: <HelmetWrapper canonicalUrl='/browse/taxonomies'><ExploreTaxon /></HelmetWrapper>
        },
        {
            path:'/browse/etymologies',
            element: <HelmetWrapper title={'Casabio | Etymologies'} description={'Etymologies Page'} canonicalUrl='/browse/etymologies'><ExploreEtymology /></HelmetWrapper>
        },
        {
            path: '/browse/observations/:id',
            element: <HelmetWrapper canonicalUrl='/browse/observations'><IndividualObservationPage /></HelmetWrapper>
        },
        {
            path: '/browse/collections',
            element: <HelmetWrapper canonicalUrl='/browse/collections'><ExploreCollectionsPage /></HelmetWrapper>
        },
        {
            path: '/browse/collections/:id',
            element: <HelmetWrapper canonicalUrl='/browse/collections'><IndividualCollectionPage /></HelmetWrapper>
        },
        {
            path: '/browse/users',
            element: <HelmetWrapper canonicalUrl='/browse/users'><ExploreUserPage /></HelmetWrapper>
        },
        {
            path: '/policies/popi',
            element: <HelmetWrapper canonicalUrl='/policies/popi'><POPIPolicyPage /></HelmetWrapper>
        },
        {
            path: '/updates',
            element: <HelmetWrapper canonicalUrl='/updates'><UpdatePage /></HelmetWrapper>
        },
        {
            path: '/profile/:tab/:id',
            element: <HelmetWrapper canonicalUrl='/profile'><Profile /></HelmetWrapper>
        }
    ]
};

export default LoginRoutes;
