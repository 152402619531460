import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import ReactGA from 'utils/analytics';
import CasabioLogo from 'assets/casabio-logo/casabio-logo-resize.png'

const HelmetWrapper = ({ children, routeURL, title, description }) => {
    // Get the current site's base URL
    const baseUrl = 'casabio.org';

    // Combine base URL with the canonical URL suffix
    const fullCanonicalUrl = `${baseUrl}${routeURL}`;

    const location = useLocation();

    useEffect(() => {
        // Track the pageview with Google Analytics
        ReactGA.send({ hitType: "pageview", page: routeURL });
    }, [routeURL, location]);

    return (
        <>
            <Helmet>
                <meta property="og:title" content={title || "Casabio - Home of Biodiversity | Explore Africa's Rich Flora and Fauna"} />
                <meta property="og:description" content={description || "Discover the world of biodiversity with Casabio. Explore the diverse flora and fauna of Africa, from plants to animals, and learn about their unique habitats and habitats."} />
                <meta property="og:image" content={CasabioLogo} />
                <meta property="og:type" content="website" />
                <link rel="canonical" href={fullCanonicalUrl} />
            </Helmet>
            {children}
        </>
    );
};

export default HelmetWrapper;
