import React from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {useTheme} from "@mui/material/styles";

const MyProfileNav = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const refreshToken = localStorage.getItem('refreshToken');

    if (!refreshToken) {
        return null; // Don't render if no refreshToken exists
    }

    return (
        <Box sx={{ pr: 2 }}>
            <Typography
                variant="h5"
                sx={{
                    cursor: 'pointer',
                    fontWeight: 500,
                    color: theme.palette.dark.main,
                    transition: 'color 0.3s ease, text-decoration 0.3s ease',
                    '&:hover': {
                        color: theme.palette.secondary.dark,
                        textDecoration: 'underline'
                    }
                }}
                onClick={() => navigate('/profile/observations')}
            >
                My Profile
            </Typography>
        </Box>
    );
};

export default MyProfileNav;
