import React from 'react';
import { Box, Typography, Tooltip } from '@mui/material';
import { VisibilityOff } from '@mui/icons-material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const scale = (size, minScale, maxScale) => `${minScale + (size - 100)*(maxScale - minScale) / 400}px`;

// eslint-disable-next-line react/prop-types
const HiddenImage = ({ width, height, ...props }) => {
    const reasons = ['- Not authorised', '- Image is tagged as a GPS', '- No image available'];
    const iconSize = scale(height, 24, 72);
    const fontH6Size = scale(height, 1.0, 1.7);
    const fontSubtitle1Size = scale(height, 0.75, 1.65);
    return (
        <Box
            sx={{
                width: width,
                height: height,
                backgroundColor: 'darkgrey',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            {...props}
        >
            <VisibilityOff sx={{ fontSize: iconSize, color: 'white' }} />
            <Typography variant="h6" sx={{ color: 'white', marginTop: 1, fontSize: fontH6Size }}>
                Hidden
            </Typography>
            <Tooltip
                title={
                    <React.Fragment>
                        <Typography variant='h3' color='white' fontSize='0.8rem'>
                            Reasons why:
                        </Typography>
                        {reasons.map((reason, index) => (
                            <Typography key={index} fontSize='0.7rem'>
                                {reason}
                            </Typography>
                        ))}
                    </React.Fragment>
                }
                sx={{
                    backgroundColor: "lightgray",
                    color: 'black',
                    fontSize: '1em',
                    borderRadius: '10px',
                    p: '10px'
                }}
            >
                <Typography variant="subtitle1" sx={{ color: 'white', marginTop: 1, cursor: 'pointer', fontSize: fontSubtitle1Size }}>
                    <InfoOutlinedIcon fontSize="small" />
                </Typography>
            </Tooltip>
        </Box>
    );
};
export default HiddenImage;