import { FormattedMessage } from 'react-intl';

// assets
import InfoOutlinedIcon from 'assets/icons/Site_details.png';

// ==============================|| MENU ITEMS - SITE DETAILS ||============================== //

const icons = {
    InfoOutlinedIcon
};

const siteDetails = {
    id: 'site-details',
    title: <FormattedMessage id="site-details-title"/>,
    caption: <FormattedMessage id="site-details-caption"/>,
    icon: icons.InfoOutlinedIcon,
    type: 'group',
    children: [
        {
            id: 'browse',
            title: <FormattedMessage id="Site Details"/>,
            type: 'collapse',
            icon: icons.InfoOutlinedIcon,
            children: [
                {
                    id: 'about-us',
                    title: <FormattedMessage id="About Us"/>,
                    type: 'item',
                    url: '/about',
                    target: false,
                },
                {
                    id: 'calendar',
                    title: <FormattedMessage id="Calendar"/>,
                    type: 'item',
                    url: '/calendar',
                    target: false,
                },
                // {
                //     id: 'donations',
                //     title: <FormattedMessage id="Donations"/>,
                //     type: 'item',
                //     url: '/donations',
                //     target: false,
                // },
                {
                    id: 'contact-us',
                    title: <FormattedMessage id="Contact Us"/>,
                    type: 'item',
                    url: '/contact-us',
                    target: false,
                }
            ]
        }
    ]
}

export default siteDetails;